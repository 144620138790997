/* eslint-disable no-console */
import * as Sentry from '@sentry/browser'
import { AccessManager } from 'twilio-common'
import type { Context } from '@nuxt/types'
import { MS_PER } from '@/services/types/Time'
interface ExtendedContext extends Context {
  $axios: any
}

const PAGE_SIZE = 10
// According to twilio, the access token is valid for 1 hour so we are using 59 minutes.
const TOKEN_EXPIRATION_TIME: number = 1000 * 60 * 57
const REDUCED_MESSAGE_OBJECT_KEYS: Array<string> = [
  'attachedMedia',
  'attributes',
  'author',
  'body',
  'dateCreated',
  'dateUpdated',
  'index',
  'lastUpdatedBy',
  'media',
  'participantSid',
  'sid',
  'subject',
  'type',
  'conversation'
]

interface IChannelService {
  $axios: any
  loadingMessages: boolean
  prevPage: (() => Promise<any>) | null
  nextPage: (() => Promise<any>) | null
  channel: any | null
  tokenExpirationDate: Date | null
  pluginState: {
    chatClient: any | null,
    accessManager: AccessManager | null
  }
  resetPluginStateListeners: () => void
  resetPluginState: () => void
  sendMessage: (message: string, aggregatedChannelSid: string, attributes: any) => Promise<number | null>
  filterImportantAttributes: (message: any) => Record<string, unknown>
  getPreviousPage: () => Promise<any | null>
  getNextPage: () => Promise<any | null>
  getMessages: (aggregatedChannelSid: string, index: string) => Promise<any | never[]>
  getChannel: (aggregatedChannelSid: string) => Promise<any | null>
  setChannelToken: () => void
  getChatClient: () => Promise<any | null>
  updateTokenCount: () => void
  getAndSetNewChatClient: () => Promise<any | null>
  tokenExpired: () => boolean
}

export default class ChannelService implements IChannelService {
  $axios: any
  loadingMessages: boolean
  prevPage: (() => Promise<any>) | null
  nextPage: (() => Promise<any>) | null
  channel: any | null
  tokenExpirationDate: Date | null
  pluginState: {
    chatClient: any | null,
    accessManager: AccessManager | null
  }

  constructor (nuxtApp: ExtendedContext) {
    this.$axios = nuxtApp.$axios
    this.loadingMessages = false
    this.nextPage = null
    this.prevPage = null
    this.channel = null
    this.tokenExpirationDate = null
    this.pluginState = {
      chatClient: null,
      accessManager: null
    }
  }

  resetPluginStateListeners () {
    this.pluginState.accessManager?.removeAllListeners()
    this.pluginState.chatClient?.removeAllListeners()
  }

  resetPluginState () {
    this.resetPluginStateListeners()
    this.pluginState = {
      chatClient: null,
      accessManager: null
    }
  }

  async sendMessage (message: string, aggregatedChannelSid: string, attributes: any) {
    if (!this.channel || this.channel.sid !== aggregatedChannelSid) {
      await this.getChannel(aggregatedChannelSid)
    }
    if (attributes) {
      // This is to send attr using twilio channel
      return await this.channel?.prepareMessage()
        .setBody(message)
        .setAttributes(attributes)
        .build()
        .send() || null
    } else {
      return await this.channel?.sendMessage(message) || null
    }
  }

  filterImportantAttributes (message: any) {
    return REDUCED_MESSAGE_OBJECT_KEYS.reduce((prev, attribute) => {
      if (!message[attribute]) {
        return prev
      }
      return ({ ...prev, [attribute]: message[attribute] })
    }, {})
  }

  async getPreviousPage () {
    if (!this.prevPage) { return null }
    const messages = await this.prevPage()
    if (messages) {
      messages.items = messages.items.map(this.filterImportantAttributes)
      this.prevPage = messages.prevPage
    }
    return messages
  }

  async getNextPage () {
    if (!this.nextPage) { return null }
    const messages = await this.nextPage()
    if (messages) {
      messages.items = messages.items.map(this.filterImportantAttributes)
      this.nextPage = messages.nextPage
    }
    return messages
  }

  async getMessages (aggregatedChannelSid: string, index: string) {
    if (!aggregatedChannelSid) { return [] }
    this.loadingMessages = true
    const channel = await this.getChannel(aggregatedChannelSid)
    if (!channel) {
      this.loadingMessages = false
      return []
    }
    const indexAsNumberOrUndefined = parseInt(index) || undefined
    const messages = await channel.getMessages(PAGE_SIZE, indexAsNumberOrUndefined)
    if (messages) {
      messages.items = messages.items.map(this.filterImportantAttributes)
      this.nextPage = messages.nextPage
      this.prevPage = messages.prevPage
    }
    this.loadingMessages = false
    return messages
  }

  async getChannel (aggregatedChannelSid: string) {
    let chatClient = await this.getChatClient()
    let channel = null
    const MAX_TRIES = 5
    for (let i = 1; i <= MAX_TRIES; i++) {
      try {
        channel = await chatClient?.getConversationBySid(aggregatedChannelSid) || null
        if (channel) { break }
      } catch (error) {
        console.log(`[getConversationBySid] failed try: ${i}/${MAX_TRIES}`, error)
        chatClient = await this.getAndSetNewChatClient()
        if (i === MAX_TRIES) { throw error }
      }
    }
    this.channel = channel
    return channel
  }

  async setChannelToken () {
    this.tokenExpirationDate = new Date(new Date().getTime() + TOKEN_EXPIRATION_TIME)
    this.updateTokenCount()
    if (this.pluginState.accessManager && this.pluginState.chatClient) {
      const updatedToken = await this.$axios.$get('/api/sms-threads/token')
      this.pluginState.accessManager.updateToken(updatedToken)
      this.pluginState.chatClient.updateToken(updatedToken)
    } else {
      console.log('pluginState: ', this.pluginState)
    }
  }

  async getChatClient () {
    if (this.pluginState.chatClient && !this.tokenExpired()) {
      console.log('[chat-plugin.js] Chat client already exists; using it...')
    } else {
      console.log('[chat-plugin.js] Creating chat client...')
      await this.getAndSetNewChatClient()
    }
    return this.pluginState.chatClient
  }

  updateTokenCount () {
    const nowDate: Date = new Date()
    const resetCount = () => {
      localStorage.setItem('tokenCountResetDate', nowDate.toString())
      localStorage.setItem('tokenCount', '1')
    }
    const resetDateStored = localStorage.getItem('tokenCountResetDate')
    const countStored = localStorage.getItem('tokenCount')
    if (!resetDateStored || !countStored) {
      resetCount()
      return
    }
    const resetDate = new Date(resetDateStored)
    const timeDiffInMinutes = (nowDate.getTime() - resetDate.getTime()) / MS_PER.Minute
    if (timeDiffInMinutes > 5) {
      resetCount()
      return
    }
    const count = +countStored + 1
    localStorage.setItem('tokenCount', count.toString())
    if (count === 1000) {
      Sentry.captureException(new Error('Token request count exceeded 1000 in less than 5 minutes'))
    }
  }

  async getAndSetNewChatClient () {
    this.resetPluginStateListeners()
    this.tokenExpirationDate = new Date(new Date().getTime() + TOKEN_EXPIRATION_TIME)
    this.updateTokenCount()
    const token = await this.$axios.$get('/api/sms-threads/token')
    this.pluginState.accessManager = new AccessManager(token)
    if (!window.Twilio) {
      console.warn('[chat-plugin.js] Twilio SDK not loaded')
      return
    }
    this.pluginState.chatClient = new window.Twilio.Conversations.Client(token)
    this.pluginState.chatClient.on('tokenAboutToExpire', () => this.setChannelToken())
    this.pluginState.chatClient.on('tokenExpired', () => this.setChannelToken())
    // AM
    this.pluginState.accessManager
      .on('tokenUpdated', (am:any) => this.pluginState.chatClient?.updateToken(am.token))
      .on('tokenExpired', () => this.setChannelToken())
      .on('tokenWillExpire', () => this.setChannelToken())
    return this.pluginState.chatClient
  }

  tokenExpired () {
    return this.tokenExpirationDate ? new Date() > this.tokenExpirationDate : true
  }
}
